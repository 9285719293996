/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import React, { useState, useEffect, lazy, Suspense, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../auth/AuthContext";

/////////////////////////////////////
// COMPONENT: ROUTER
/////////////////////////////////////

let scrollInstance: LocomotiveScroll | null = null; // Global scroll instance

const Router: React.FC = (): JSX.Element => {
  const [scroll, setScroll] = useState<LocomotiveScroll | null>(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      import("locomotive-scroll").then((locomotiveModule) => {
        const ScrollEl = scrollRef.current as any;
        const newScroll = new locomotiveModule.default({
          el: ScrollEl,
          smooth: true,
          multiplier: 1.2,

          class: "is-reveal",
        });

        setScroll(newScroll);
        scrollInstance = newScroll; // Set the global scroll instance

        new ResizeObserver(() => newScroll.update()).observe(ScrollEl);

        return () => newScroll.destroy();
      });
    }
  }, []);

  const Home = lazy(() => import("../pages/Home/Home"));
  const Authentication = lazy(
    () => import("../pages/Authentication/Authentication")
  );
  const About = lazy(() => import("../pages/About/About"));
  const AssignmentsHub = lazy(
    () => import("../pages/AssignmentsHub/AssignmentsHub")
  );
  const Bibliographies = lazy(
    () => import("../pages/Bibliographies/Bibliographies")
  );
  const ErrorPage = lazy(() => import("../pages/404/ErrorPage"));

  const AssignmentOne = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentOne")
  );
  const AssignmentTwo = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentTwo")
  );
  const AssignmentThree = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentThree")
  );
  const AssignmentFour = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentFour")
  );
  const AssignmentFive = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentFive")
  );
  const AssignmentSix = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentSix")
  );
  const AssignmentSeven = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentSeven")
  );
  const AssignmentEight = lazy(
    () => import("../pages/Assingments/AssingmentPages/AssignmentEight")
  );
  const FinalProject = lazy(
    () => import("../pages/Assingments/AssingmentPages/FinalProject")
  );

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Home scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/authentication"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Authentication scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignments-hub"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentsHub scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/about"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <About scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-1"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentOne scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-2"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentTwo scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-3"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentThree scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-4"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentFour scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-5"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentFive scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-6"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentSix scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-7"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentSeven scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/assignment-8"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AssignmentEight scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/bibliographies"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Bibliographies scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="/final-project"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <FinalProject scroll={scroll} />
                </Suspense>
              </div>
            }
          />
          <Route
            path="*"
            element={
              <div ref={scrollRef}>
                <Suspense fallback={<div>Loading...</div>}>
                  <ErrorPage />
                </Suspense>
              </div>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

// Export scrollInstance for other files to use
export const getScrollInstance = () => scrollInstance;

export const scrollToLocomotive = (
  target: string | HTMLElement,
  options = {}
) => {
  const scroll = getScrollInstance();
  if (scroll) {
    scroll.scrollTo(target, options);
  } else {
    console.warn("Scroll instance not initialized yet.");
  }
};

export default Router;
