import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { db, auth } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { User } from "firebase/auth";

interface UserData {
  assignments: Array<Object>;
  email: string;
  averageFinalGrade?: number;
  name: string;
  school: string;
}

interface AuthContextType {
  currentUser: any | null;
  userData: UserData | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);

  // Track authentication state
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(
      async (user: User | null) => {
        setCurrentUser(user);
      }
    );

    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Fetch user data once after login
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "user-data", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            setUserData(userDocSnap.data() as UserData);
          } else {
            setUserData(null);
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
          setUserData(null);
        }
      } else {
        setUserData(null);
      }
    };

    fetchUserData();
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, userData }}>
      {children}
    </AuthContext.Provider>
  );
};
