/////////////////////////////////////
// IMPORTING LIBRARIES
/////////////////////////////////////

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  addDoc,
  DocumentReference,
  DocumentData,
  DocumentSnapshot,
} from "firebase/firestore";

import { authErrors } from "../data/firebaseErrors";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { Navigate } from "react-router-dom";
import alertMessage from "../components/Alert/Alert";
import React from "react";

export let isAuthenticated: any = null;
export let user: null | any = null;
export let uid: null | any = null;
/////////////////////////////////////
// CONFIG: FIREBASE
/////////////////////////////////////

const firebaseConfig = {
  apiKey: "AIzaSyBVg66KzX28EpXzhV4kI9W6yMO0pRUtQgg",
  authDomain: "saudi-stock-digest.firebaseapp.com",
  projectId: "saudi-stock-digest",
  storageBucket: "saudi-stock-digest.appspot.com",
  messagingSenderId: "565114050282",
  appId: "1:565114050282:web:c1f80e022856c26a146993",
  measurementId: "G-N89B03K2B2",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

/**
 * User sign up function that creates a new user account with email and password.
 *
 * @param {string} email - The email of the user to create
 * @param {string} password - The password of the user to create
 * @returns {null} No explicit return value
 */

export const getAssingments = () => {
  return new Promise((resolve, reject) => {
    if (user) {
      const docRef = doc(db, "user-data", user.uid);
      getDoc(docRef)
        .then((docSnapshot) => {
          console.log(docSnapshot.data()); // Log the data from the document snapshot

          resolve(docSnapshot);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      reject(new Error("User object is null or undefined"));
    }
  });
};

export const signUpFirebase = async ({
  name,
  email,
  school,
  password,
}: {
  name: string;
  school: string;
  email: string;
  password: string;
}): Promise<boolean> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    user = userCredential.user;

    await setDoc(doc(db, "user-data", user.uid), {
      name,
      email,
      school,
      assignments: {
        1: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        2: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        3: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        4: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        5: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        6: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        7: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        8: {
          completed: false,
          mcq: [],
          "long-answer": "",
        },
        9: {
          completed: false,
          "long-answer": "",
        },
      },
    });

    await sendEmailVerification(user);

    return true;
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    return false;
  }
};
console.log(user);

export const verifyEmail = async (email: string) => {
  try {
    alertMessage("Email verification sent to email!");
  } catch (error: any) {
    console.log(error.message);
    if (
      error.code === "auth/user-not-found" ||
      error.code === "auth/missing-email"
    ) {
      alertMessage("User not found, try typing it into the email field again!");
    }
  }
};

export const addAssignmentGradesToDatabase = async ({
  assignmentNumber,
  mcqPercentageGrade,
  mcq,
  aiFeedback,
  longAnswer,
}: {
  assignmentNumber: number;
  mcqPercentageGrade: number | null | undefined;
  mcq: Array<string | number> | null | undefined;
  aiFeedback: string | null | undefined;
  longAnswer: string;
}) => {
  const userdata = await getUserData();
  try {
    const docRef: DocumentReference<DocumentData> = doc(db, "user-data", uid);
    await setDoc(docRef, {
      ...userdata,
      assignments: {
        ...userdata.assignments,
        [assignmentNumber]: {
          // Use square brackets for dynamic keys
          completed: true,
          mcq: mcq || null,
          aiFeedback: aiFeedback || null,
          "mcq-percentage-grade": mcqPercentageGrade || null,
          "date-submitted": Date.now(),
          "long-answer": longAnswer,
        },
      },
    });
    console.log("Assignment grades added successfully!");
  } catch (error) {
    console.error("Error adding assignment grades:", error);
  }
};

/**
 * Function to sign in to Firebase with the provided email and password.
 *
 * @param {string} email - The user's email for signing in.
 * @param {string} password - The user's password for signing in.
 * @return {void} This function does not return anything.
 */

export const signInFirebase = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): void => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      user = userCredential.user;
      // check if user already has done email verification
      if (!user?.emailVerified) {
        alertMessage("Please verify your email address first!");
      } else {
        alertMessage("User signed in successfully.");
      }
    })
    .catch((error: any) => {
      const errorCode = error.code;

      const errorMessage = error.message;
      alertMessage(errorMessage);
    });
};

export const resetPasswordEmail = async (email: string | null) => {
  if (email) {
    try {
      await sendPasswordResetEmail(auth, email);
      alertMessage("Password reset sent to email!");
    } catch (error: any) {
      console.log(error.message);
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/missing-email"
      ) {
        alertMessage(
          "User not found, try typing it into the email field again!"
        );
      }
    }
  } else {
    alertMessage("Please enter an email address");
  }
};

export const getUserData = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const userDocRef = doc(db, "user-data", uid);

      const userDocSnapshot: any = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        resolve(userDocSnapshot.data());
      } else {
        console.log("User document does not exist");
        resolve(null);
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      reject(error);
    }
  });
};

export const signOutFirebase = () => {
  auth.signOut();
};

export const averageFinalGrade = async () => {
  const userdata = await getUserData();
  try {
    const docRef: DocumentReference<DocumentData> = doc(db, "user-data", uid);
    const firstEightAssignments = Object.values(userdata.assignments).slice(
      0,
      8
    );

    const totalGrade = firstEightAssignments.reduce(
      (sum: number, assignment: any) =>
        sum + (assignment as any)["mcq-percentage-grade"],
      0
    );

    const averageGrade = totalGrade / firstEightAssignments.length;

    await setDoc(docRef, {
      ...userdata,
      averageFinalGrade: averageGrade,
    });
    console.log("Assignment grades added successfully!");
  } catch (error) {
    console.error("Error adding assignment grades:", error);
  }
};

export const deleteUserFirebase = async () => {
  await deleteDoc(doc(db, "user-data", user.uid));
  auth.currentUser?.delete();
};

onAuthStateChanged(auth, (user) => {
  let website = window.location.hostname;
  const assignmentPagesList = [
    "assignments-hub",
    "assignment-1",
    "assignment-2",
    "assignment-3",
    "assignment-4",
    "assignment-5",
    "assignment-6",
    "assignment-7",
    "assignment-8",
    "final-project",
  ];
  if (user) {
    let websiteSublink = window.location.href.split("/")[3];
    uid = user.uid;
    console.log("auth listener");
    isAuthenticated = true;
    if (websiteSublink == "authentication" && user.emailVerified === true) {
      setTimeout(() => {
        window.location.href = "/assignments-hub";
      }, 2000);
      console.log(website);
    }
    if (!user.emailVerified && assignmentPagesList.includes(websiteSublink)) {
      window.location.href = "/authentication";
      alert("Please verify your email address first!");
    }
  } else {
    isAuthenticated = false;

    let websiteSublink = window.location.href.split("/")[3];
    if (assignmentPagesList.includes(websiteSublink)) {
      window.location.href = "/authentication";
    }
    // User is signed out
    // ...
  }
});

/**
 * Function for a protected route.
 *
 * @param {any} children - the child components to be rendered
 * @param {any} redirectTo - the path to redirect to if not authenticated
 * @return {any} the rendered child components if authenticated, or a redirect if not
 */

export const ProtectedRoute = ({ children, redirectTo }: any) => {
  return isAuthenticated && user.emailVerified === true ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
};
